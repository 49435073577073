import React from "react"
import { Link } from "gatsby"

import Seo from "../components/Seo"
import Tabs from "../components/Tabs"
import TabContent from "../components/TabContent"
import TextContainer from "../components/TextContainer"
import CourseLayout from "../components/CourseLayout"
import TabsContainer from "../components/TabsContainer"

import * as data from "../courseData"

export default function Course() {
  const { title, hours, price, name, id } = data.small
  return (
    <CourseLayout
      title={title.main}
      subtitle={title.second}
      hours={hours}
      grossPrice={price.gross}
      newGrossPrice={price.new_gross}
      netPrice={price.net}
      name={name}
      id={id}
    >
      <Seo
        title="Kurs księgowości małych firm"
        description={`Kurs samodzielnego księgowania małej firmy we Wrocławiu od ${price.gross} zł`}
      />
      <TextContainer>
        <h3>Kurs księgowości małych firm</h3>
        <p>
          Jesteś początkującym przedsiębiorcą? Myślisz o założeniu własnej
          firmy? Naucz się samodzielnie prowadzić rachunkowość w uznanej
          wrocławskiej firmie szkoleń księgowych.
        </p>

        <h3>Naucz się samodzielnie prowadzić księgowość małej firmy</h3>
        <p>
          Samodzielne prowadzenie księgowości małej firmy nie jest trudne. Kurs
          „księgowość małej firmy” przygotowuje do samodzielnego prowadzenia
          rozliczeń z fiskusem za pomocą ryczałtu od przychodów
          ewidencjonowanych lub podatkowej księgi przychodów i rozchodów (KPiR).
          Jeżeli chcesz rozliczać spółkę z o.o. - powinieneś wybrać{" "}
          <Link to="/kursy-ksiegowosci/">kurs księgowości od postaw</Link>.
        </p>
        <h3>Na kursie nauczymy Cię</h3>
        <ul>
          <li>Wyboru formy opodatkowania podatkiem dochodowym</li>
          <li>
            Podstaw prawnych prowadzenia PKPiR oraz ryczałtu od przychodów
            ewidencjonowanych
          </li>
          <li>Dokumentowania operacji gospodarczych</li>
          <li>Rozliczeń podatku VAT</li>
          <li>Ogólnych zasad VAT zawartych w przepisach prawnych</li>
          <li>Zasad wystawiania dokumentów</li>
          <li>Księgowania w podatkowej księdze przychodów i rozchodów</li>
          <li>
            Księgowania w ewidencji ryczałtu od przychodów ewidencjonowanych
          </li>
          <li>Naliczania amortyzacji środków trwałych</li>
          <li>
            Ustalanie zaliczek na podatek dochodowy, sporządzania rocznego
            zeznania podatkowego
          </li>
        </ul>
        <h3>Zajęcia praktyczne przygotowują Cię do pracy</h3>
        <p>
          W trakcie szkolenia kładziemy nacisk na zajęcia praktyczne. W
          popularnym księgowym Rachmistrz GT - tworzymy firmę od samego
          początku. Dodajemy przychody, odejmujemy koszty, obliczamy dochód,
          należne podatki, odliczamy składki ZUS. podatek VAT, rozliczamy
          podatek VAT, sporządzamy deklaracje VAT oraz PIT. Tematem zajęć będą
          typowe operacje, z którymi spotyka się na codzień księgowy małej
          firmy.
        </p>
        <p>
          Księgowość wymaga znajomości podstawowych pojęć z zakresu ekonomii i
          prawa podatkowego. Podczas części teoretycznej uczymy jak poruszać się
          w gąszczu aktów prawnych , w jaki sposób szukać informacji, znaleźć
          pomoc w rozwiązaniu problemu.
        </p>
        <p>
          Materiał kursu obejmuje również podatek VAT. Jego zagadnienia
          przysparzają kłopotów nawet doświadczonym księgowym. Starannie
          opracowaliśmy tę część kursu, aby przekazać wiedzę i umiejętności
          które zapewnią samodzielność naszym kursantom - aby mogli samodzielnie
          sporządzać rozliczenia oraz deklaracje VAT.
        </p>
        <TabsContainer>
          <Tabs elements={["Program kursu", "Harmonogram kursu"]}>
            <TabContent index={0}>
              <h3>Program kursu księgowość małych firm</h3>
              <ul>
                <li>
                  Formy prowadzenia działalności gospodarczej w Polsce
                  <ul>
                    <li>dopuszczalne formy prowadzenia działalności</li>
                    <li>aspekty prawne i podatkowe</li>
                  </ul>
                </li>
                <li>
                  Uproszczone formy opodatkowania i prowadzenia księgowości
                  <ul>
                    <li>
                      karta podatkowa - podstawy prawne, warunki opodatkowania i
                      obowiązki podatnika
                    </li>
                    <li>
                      ryczałt od przychodów ewidencjonowanych - podstawy prawne,
                      warunki opodatkowania i obowiązki podatnika
                    </li>
                    <li>
                      Podatkowa Księga Przychodów i Rozchodów (PKPiR) - podstawy
                      prawne, warunki opodatkowania i obowiązki podatnika
                    </li>
                  </ul>
                </li>
                <li>
                  Podatek dochodowy od osób fizycznych
                  <ul>
                    <li>
                      ustawa o zryczałtowanym podatku dochodowym od niektórych
                      przychodów osiąganych przez osoby fizyczne
                    </li>
                    <li>ustawa o podatku dochodowym od osób fizycznych</li>
                    <li>ograniczony i nieograniczony obowiązek podatkowy</li>
                    <li>
                      ustalanie podstawy opodatkowania na podstawie prawidłowo
                      prowadzonych ksiąg
                    </li>
                    <li>zwolnienia przedmiotowe z podatku dochodowego</li>
                    <li>
                      koszty uzyskania przychodów według prawa podatkowego
                    </li>
                    <li>dochody wyłączone z dochodu globalnego podatnika</li>
                    <li>odliczenia od dochodu i podatku</li>
                    <li>wybór najkorzystniejszej formy opodatkowania</li>
                  </ul>
                </li>
                <li>
                  Samochód w firmie
                  <ul>
                    <li>samochód właściciela i pracownika</li>
                    <li>
                      koszty eksploatacyjne samochodu w kosztach uzyskania
                      przychodów
                    </li>
                  </ul>
                </li>
                <li>
                  Delegacje
                  <ul>
                    <li>
                      rozporządzenie Ministra Pracy i Polityki Społecznej w
                      sprawie należności przysługujących pracownikowi
                      zatrudnionemu w państwowej lub samorządowej jednostce
                      sfery budżetowej z tytułu podróży służbowej
                    </li>
                    <li>delegacja krajowa</li>
                    <li>delegacja zagraniczna</li>
                  </ul>
                </li>
                <li>
                  Podatek od towarów i usług VAT
                  <ul>
                    <li>
                      podstawy prawne: ustawa o podatku od towarów i usług,
                      rozporządzenia
                    </li>
                    <li>podstawowe pojęcia dotyczące podatku VAT</li>
                    <li>powstania obowiązku podatkowego w podatku VAT</li>
                    <li>zwolnienia przedmiotowe i podmiotowe z podatku VAT</li>
                    <li>VAT należny a naliczony- definicje VAT</li>
                    <li>zasady i terminy wystawiania faktur</li>
                    <li>podatek VAT-transakcje wewnątrzwspólnotowe</li>
                  </ul>
                </li>
                <li>
                  środki trwałe
                  <ul>
                    <li>wartość początkowa środków trwałych</li>
                    <li>amortyzacja środków trwałych</li>
                    <li>
                      remont i modernizacja środków trwałych-skutki podatkowe
                    </li>
                    <li>sprzedaż i likwidacja środków trwałych</li>
                  </ul>
                </li>
                <li>
                  Leasing środków trwałych
                  <ul>
                    <li>leasing finansowy – aspekt księgowy i podatkowy</li>
                    <li>leasing operacyjny - aspekt księgowy i podatkowy</li>
                  </ul>
                </li>
                <li>
                  Zeznania roczne i deklaracje podatkowe
                  <ul>
                    <li>wybór rocznego zeznania podatkowego</li>
                    <li>terminy składania zeznań</li>
                    <li>jednolity plik kontrolny JPK i deklaracja VAT</li>
                  </ul>
                </li>
              </ul>
            </TabContent>
            <TabContent index={1}>
              <h3>Harmonogram kursu księgowość małych firm</h3>
              <h5>Zajęcia nr 1</h5>
              <p>
                <ol>
                  <li>formy prowadzenia działalności gospodarczej w Polsce</li>
                  <li>
                    opodatkowanie w formie karty podatkowej
                    <ul>
                      <li>
                        <li>podstawa prawna</li>
                        <li>warunki opodatkowania w formie karty podatkowej</li>
                        <li>
                          obowiązki podatnika rozliczającego się kartą podatkową{" "}
                        </li>
                      </li>
                    </ul>
                  </li>
                  <li>
                    opodatkowanie ryczałtem od przychodów ewidencjonowanych
                    <ul>
                      <li>podstawa prawna</li>
                      <li>warunki wyboru opodatkowania w formie ryczałtu </li>
                      <li>
                        {" "}
                        zaksięgowanie przychodów ryczałtowca w programie
                        księgowym Rachmistrz, sporządzenie ewidencji sprzedaży,
                        obliczenie zryczałtowanego podatku{" "}
                      </li>
                    </ul>
                  </li>
                </ol>
              </p>
              <h5>Zajęcia nr 2</h5>
              <p>
                <ol>
                  <li>
                    podatkowa księga przychodów i rozchodów (PKPiR)
                    <ul>
                      <li>
                        warunki wyboru prowadzenia księgowości uproszczonej w
                        formie PKpiR
                      </li>
                      <li>
                        sposób prowadzenia księgi-Rozporządzenie Ministra
                        Finansów w sprawie prowadzenia podatkowej księgi
                        przychodów i rozchodów
                      </li>
                      <li>dokumenty uznane za dowody księgowe</li>
                      <li>rozliczenie remanentów</li>
                      <li>
                        załącznik do rozporządzenia-wzór księgi, objaśnienia
                        zapisów w poszczególnych kolumnach podatkowej księgi
                        przychodów i rozchodów
                      </li>
                      <li>
                        założenie firmy rozliczającej się PKpiR w programie
                        Rachmistrz-księgowanie dokumentów sprzedaży i zakupu,
                        różnice remanentowe, wydruk książki i zestawień.
                      </li>
                    </ul>
                  </li>
                </ol>
              </p>
              <h5>Zajęcia nr 3</h5>
              <p>
                <ol>
                  <li>
                    podatek dochodowy od osób fizycznych
                    <ul>
                      <li>
                        podstawa prawna: ustawa o podatku dochodowym od osób
                        fizycznych
                      </li>
                      <li>ograniczony i nieograniczony obowiązek podatkowy</li>
                      <li>
                        obliczanie dochodu na podstawie prawidłowo prowadzonych
                        ksiąg
                      </li>
                      <li>
                        data uzyskania przychodu z działalności gospodarczej
                      </li>
                      <li>zwolnienia przedmiotowe z podatku dochodowego</li>
                      <li>
                        koszty uzyskania przychodów według prawa podatkowego
                      </li>
                      <li>
                        przykłady wyliczanie podatku wg zasad ogólnych oraz
                        podatku liniowego
                      </li>
                      <li>dochody wyłączone z dochodu globalnego podatnika </li>
                      <li>odliczenia od dochodu i podatku</li>
                      <li>wybór najkorzystniejszej formy opodatkowania</li>
                      <li>samochód w firmie</li>
                    </ul>
                  </li>
                </ol>
              </p>
              <h5>Zajęcia nr 4</h5>
              <p>
                <ol>
                  <li>
                    księgowanie w programie księgowym, odliczanie składek ZUS,
                    sporządzanie zaliczek PIT-5
                  </li>
                  <li>
                    rozporządzenie w sprawie delegacji- przykład rozliczenia
                    delegacji krajowej
                  </li>
                  <li>naliczanie i księgowanie wynagrodzeń oraz składek ZUS</li>
                  <li>
                    test- ugruntowanie wiedzy dotyczącej kosztów uzyskania
                    przychodów
                  </li>
                </ol>
              </p>
              <h5>Zajęcia nr 5</h5>
              <p>
                <ol>
                  <li>
                    podatek od towarów i usług
                    <ul>
                      <li>
                        podstawy prawne: ustawa o podatku od towarów i usług,
                        rozporządzenia
                      </li>
                      <li>podstawowe pojęcia dotyczące podatku VAT</li>
                      <li>
                        zasady ogólne i szczególne momenty powstania obowiązku
                        podatkowego w podatku VAT
                      </li>
                      <li>
                        zwolnienia przedmiotowe i podmiotowe z podatku VAT
                      </li>
                      <li>odliczanie podatku VAT naliczonego</li>
                      <li>zasady i terminy wystawiania faktur</li>
                      <li>podatek VAT-transakcje wewnątrzwspólnotowe</li>
                    </ul>
                  </li>
                </ol>
              </p>
              <h5>Zajęcia nr 6</h5>
              <p>
                <ol>
                  <li>
                    utworzenie w programie księgowym firmy – podatnika VAT
                    <ul>
                      <li>księgowanie faktur zakupu i sprzedaży</li>
                      <li>sporządzanie ewidencji i rejestrów VAT</li>
                      <li>
                        sporządzanie i objaśnienia poszczególnych pozycji
                        deklaracji podatkowej VAT
                      </li>
                    </ul>
                  </li>
                </ol>
              </p>
              <h5>Zajęcia nr 7</h5>
              <p>
                <ol>
                  <li>
                    obowiązek ewidencjonowania za pomocą kas
                    fiskalnych-rozporządzenie ministra finansów w sprawie kas
                    rejestrujących
                    <ul>
                      <li>
                        zwolnienia z obowiązku posiadania i rejestrowania
                        obrotów za pomocą kas fiskalnych
                      </li>
                    </ul>
                  </li>
                  <li>
                    księgowanie w programie księgowym wybranych operacji
                    gospodarczych: import usług, obciążenie odwrotne, nietypowe
                    operacje gospodarcze
                  </li>
                </ol>
              </p>
              <h5>Zajęcia nr 8</h5>
              <p>
                <ol>
                  <li>
                    środki trwałe
                    <ul>
                      <li>ustalanie wartości początkowej środków trwałych</li>
                      <li>metody amortyzacji środków trwałych</li>
                      <li>
                        pojęcia remontu i modernizacji środków trwałych-skutki
                        podatkowe
                      </li>
                      <li>
                        ćwiczenia w programie księgowym-wprowadzanie do
                        ewidencji środków trwałych, naliczanie i księgowanie
                        amortyzacji, likwidacja środka trwałego
                      </li>
                    </ul>
                  </li>
                </ol>
              </p>
              <h5>Zajęcia nr 9</h5>
              <p>
                <ol>
                  <li>sporządzanie deklaracji podatkowych PIT 36 i PIT 28</li>
                  <li>
                    księgowanie operacji gospodarczych w programie księgowym
                    Rachmistrz
                  </li>
                </ol>
              </p>
              <h5>Zajęcia nr 10</h5>
              <ol>
                <li>
                  Zadania do samodzielnego wykonania-wybrane operacje do
                  zaksięgowania w programie księgowym, samodzielne obliczenia
                  podatkowe
                </li>
              </ol>
            </TabContent>
          </Tabs>
        </TabsContainer>
      </TextContainer>
    </CourseLayout>
  )
}
